import AxiosHelper from "@/helpers/AxiosHelper.js";

export default {
  authorize() {
    return AxiosHelper.authClient.get("/v1/login/web/authorize-url");
  },
  token(form, clientType) {
    let config = {
      headers: { "X-SBP-CLIENT-TYPE": clientType },
    }
    return AxiosHelper.authClient.post("/v1/login/web/token", form, config);
  },
  myAccount() {
    return AxiosHelper.authClient.get("/v1/login/web/gov-br-url");
  },
  logout() {
    return AxiosHelper.authClient.get("/v1/login/web/logout-url");
  },
};
